import React from "react";
import { Container, Header, Grid, Segment, Icon } from "semantic-ui-react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import CircleImage from "../components/how-to-use/CircleImage";
import RulesFile from "../static/rules.pdf";

const HowToUsePage = () => (
  <Layout activePage="how-to-use">
    <SEO title="Pakiety" />
    <Segment
      vertical
      style={{
        background: "none",
        margin: "0 0 0 0",
        minHeight: "100%",
      }}
    >
      <Container text>
        <Header
          as="h1"
          content="JAK SKORZYSTAĆ?"
          style={{
            margin: "1em 0 1em 0",
            textAlign: "center",
          }}
        />
        <Header
          as="h2"
          content="Skorzystanie z usługi Doradcy Informatycznego jest niezwykle proste i szybkie!"
          style={{
            margin: "1em 0 1em 0",
            textAlign: "center",
            color: "orange",
          }}
        />
      </Container>
      <Container>
        <Grid stackable container relaxed columns="equal">
          <Grid.Row stretched>
            <Grid.Column>
              <CircleImage>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    background: "none",
                    textAlign: "center",
                    padding: "35% 0",
                    verticalAlign: "middle",
                  }}
                >
                  <h4>
                    Wybierz jeden <br />
                    <Link to="/services">z trzech pakietów</Link>
                    <br />
                    dostępnych na{" "}
                    <a href="https://medicover24.doradcainformatyczny.pl">
                      platformie
                    </a>
                  </h4>
                </div>
              </CircleImage>
            </Grid.Column>
            <Grid.Column>
              <CircleImage>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    background: "none",
                    textAlign: "center",
                    padding: "35% 0",
                    verticalAlign: "middle",
                  }}
                >
                  <h4>
                    Skontaktuj się
                    <br />
                    z infolinią :
                    <br />
                    <a href="tel:223307702">22 330 77 02</a>
                    <br />i podaj kod dostępu
                  </h4>
                </div>
              </CircleImage>
            </Grid.Column>
            <Grid.Column>
              <CircleImage>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    background: "none",
                    textAlign: "center",
                    margin: "35% 0",
                    verticalAlign: "middle",
                  }}
                >
                  <h4>
                    Konsultant
                    <br />
                    zweryfikuje Twoje
                    <br />
                    dane i połączy Cię
                    <br />z ekspertem IT!
                  </h4>
                </div>
              </CircleImage>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid centered columns={2} textAlign="center">
              <a
                href="https://medicover24.doradcainformatyczny.pl"
                className="ui orange button"
              >
                <Icon name="external" />
                Skorzystaj z usługi
              </a>
              <a
                href={RulesFile}
                className="ui orange button"
                download="Regulamin Świadczenia Usługi Medicover "
              >
                <Icon name="download" />
                Regulamin usługi
              </a>
            </Grid>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </Layout>
);

export default HowToUsePage;
