import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

const BackgroundSection = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "circle.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 350) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage Tag="section" fluid={imageData}>
          {children}
        </BackgroundImage>
      );
    }}
  />
);

export default styled(BackgroundSection)`
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: left top;
`;
